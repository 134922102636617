import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Ласкаво просимо до Aniliun Pub
			</title>
			<meta name={"description"} content={"Місце, де збираються фанати і народжуються легенди"} />
			<meta property={"og:title"} content={"Ласкаво просимо до Aniliun Pub"} />
			<meta property={"og:description"} content={"Місце, де збираються фанати і народжуються легенди"} />
			<meta property={"og:image"} content={"https://aniliunbast.com/img/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://aniliunbast.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aniliunbast.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aniliunbast.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://aniliunbast.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.6) 0%,rgba(25, 30, 34, 0.6) 100%),--color-darkL2 url(https://aniliunbast.com/img/map.png)"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Відвідайте Нас
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Вул. І. Петраша 3, Яремче
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Написати
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:admin@aniliunbast.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									admin@aniliunbast.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Замовте столик за номером
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								099 427 44 26
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});